import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO />
    <div id="home-container">
      <div>
        <p className="text">
          I am <span>Kourosh Parsee</span>, a <span>Software Developer</span> in{' '}
          <span>Vancouver, BC</span>.
        </p>
        <p className="text">
          Find out more about my{' '}
          <a href="https://www.linkedin.com/in/kouroshp/">work</a>, my{' '}
          <a href="https://github.com/kouroshp">code</a>, or{' '}
          <Link to="/contact">contact me</Link>.
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
